import axiosService from '../../../services/axiosService';
import { CONFIG } from '../../../configs';
import TokenUtil from '../Utils/TokenUtil';
import DateUtil from '../Utils/DateUtil';
import { ACTION_TYPES, DATE_FORMAT } from '../Constants';

import axios from 'axios';
import { TOKEN_NAME } from '../../../constants';

const axiosInstance = axios.create({ baseURL: CONFIG.API_URL.BASE });

export function getAccountList(dataReq) {

  return axiosService.get(`${CONFIG.API_URL.BASE}/auth/user`, dataReq)
}

export function getDetailAccount(id) {
  return axiosService.get(`${CONFIG.API_URL.BASE}/auth/user/${id}`)
}

export function addNewAccount(data) {
  return axiosService.post(`${CONFIG.API_URL.BASE}/auth/user`, data);
}


export function updateAccountStaff(id, data) {
  return axiosService.put(`${CONFIG.API_URL.BASE}/auth/user/${id}`, data);
}

export function deleteAccountStaff(list_id, data) {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/auth/user/${list_id}`, data);
}
//11111111111111111

export function getDepartmentList(dataReq) {

  return axiosService.get(`${CONFIG.API_URL.BASE}/auth/department`, dataReq)
}

export function getDetailDepartment(id) {

  return axiosService.get(`${CONFIG.API_URL.BASE}/auth/department/${id}`).then(({ data }) => {
    return data.data;
  }).catch(({ data: err }) => {
    return err;
  });
}

export function addNewDepartment(data) {
  return axiosService.post(`${CONFIG.API_URL.BASE}/auth/department`, data);
}

export function updateDepartment(id, data) {
  return axiosService.put(`${CONFIG.API_URL.BASE}/auth/department/${id}`, data);
}

export function deleteDepartment(list_id) {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/auth/department/${list_id}`);
}

//22222222222222

export function getGroupUserList(dataReq) {

  return axiosService.get(`${CONFIG.API_URL.BASE}/auth/permission-group`, dataReq)
}

export function getDetailGroupUser(id) {

  return axiosService.get(`${CONFIG.API_URL.BASE}/auth/permission-group/${id}`)
}

export function addNewGroupUser(data) {
  return axiosService.post(`${CONFIG.API_URL.BASE}/auth/permission-group`, data);
}


export function updateGroupUser(id, data) {
  return axiosService.put(`${CONFIG.API_URL.BASE}/auth/permission-group`, data);
}

export function deleteGroupUser(list_id) {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/auth/permission-group/${list_id}`);
}

export const uploadFile = async (url, data) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME);
    let formData = new FormData();
    if (data.file) formData.append("file", data.file);
    const result = await axiosInstance.post(url, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};