import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { message as Message, Modal, Spin } from 'antd';
import { deleteProject, resetPassword, resetPasswordAdmin } from "../../services/delete";
import { handleErrors } from "../../utils/handleErrors";

export const ResetPasswordConfirm = ({ data, isShow, setIsShow, user, handleGet, isAdmin = false }) => {
  const [deleting, setDeleting] = useState(false);
  const handleOk = async function () {
    try {
      setDeleting(true);
      // TODO
      const result = isAdmin ? await resetPasswordAdmin(data?.account?.id) : await resetPassword(data.userId);
      if (isAdmin) {
        if (result.status === 200) {
          setDeleting(false);
          setIsShow(false);
          Message.success('Mật khẩu đã được đặt về Abcd1234', 10);
        }
        return
      }
      if (result.status === 204) {
        setDeleting(false);
        setIsShow(false);
        Message.success('Mật khẩu đã được đặt về Abcd1234', 10);
      }
    } catch (e) {
      setDeleting(false);
      setIsShow(false);
      console.log('e', e.response)
      Message.error(e.response?.data?.message || "Chỉ có account admin mới có quyền này", 10);
      // handleErrors(e, user.logoutUser, setDeleting, 'Xóa nghiên cứu không thành công!');
    }
  }

  return (<Modal
    visible={isShow}
    title={'Yêu cầu xác nhận!'}
    width={500}
    onCancel={() => setIsShow(false)}
    maskClosable={false}
    onOk={() => handleOk(data._id)}
    confirmLoading={deleting}
    okText={'Xác nhận'}
    cancelText={'Hủy'}
    destroyOnClose
  >
    <Spin spinning={deleting} tip={'Đang thực hiện...'}>
      {`Bạn có chắc chắn muốn đặt lại lại mật khẩu cho tài khoản này không ?`}
    </Spin>
  </Modal>);
};


