import jwt from 'jwt-decode';
import { refreshTokenApi } from "../apis/login";
import { REFRESH_TOKEN, TOKEN_NAME } from "../constants";
import { loginSuccess } from '../context/authContext/AuthActions';

export const checkExpiredToken = (token) => {
  try {
    if (token) {
      return true; // todo: data mẫu, xóa dòng này khi làm chính thức
      // const jwtDecode = jwt(token);
      // const currentTime = Math.floor(new Date().getTime() / 1000);
      // if (jwtDecode.exp > currentTime) {
      //     return true;
      // }
    }
    return false;
  } catch {
    return false;
  }
};

export const getTokenInfo = (token) => {
  if (checkExpiredToken(token)) {
    return jwt(token);
  }
  return null;
};


// export const refreshToken = async () => {
//     const res = await refreshTokenApi()
//     if (res.data.status === 1) {
//
//     }
// }
export const refreshToken = (function () {
  var isCalled = false

  async function handleRefreshToken() {
    localStorage.setItem(TOKEN_NAME, "")
    window.location.reload()
    return;
    const res = await refreshTokenApi()
    if (res.status === 200) {
      const data = res.data
      localStorage.setItem(TOKEN_NAME, data.token)
      localStorage.setItem("roles", data.roles)
      localStorage.setItem("userId", data.userId)
      localStorage.setItem("username", data.username)
      window.location.reload()
    } else {
      localStorage.setItem(TOKEN_NAME, "")
      window.location.reload()
    }
  }

  var publicInterface = {
    call: function () {
      if (isCalled) return
      // console.log('refresh token called');
      isCalled = true
      handleRefreshToken().then()
    }
  }

  return publicInterface // Object will contain public methods
})()
