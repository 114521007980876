import axiosService from '../../services/axiosService';
import { CONFIG } from '../../configs';
import { REFRESH_TOKEN, TOKEN_NAME } from "../../constants";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */

export const loginApi = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/login`, data)
};

export const verifyLoginApi = async ({ username, password }) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/verify-password?username=${username}&password=${password}`)
};

export const logoutApi = async () => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.get(`${CONFIG.API_URL.AUTH}/auth/logout`, '', {
    Authorization: `Bearer ${token}`,
  })
};


export const refreshTokenApi = async () => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/refresh-token`, '', {
    Authorization: `Bearer ${token}`,
  })
}

export const changePasswordApi = async (data) => {
  console.log('data', data)
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/reset-password`, {
    token,
    newPassword: data.password
  }, {
    Authorization: `Bearer ${token}`,
  })
}

export const changePasswordApiReset = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/reset-password`, {
    token: data.token,
    newPassword: data.password
  }, {
    Authorization: `Bearer ${data.token}`,
  })
}

export const forgotPasswordApi = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/forgot_password`, data)
};

export const confirmForgotPasswordApi = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/change_otp_password`, data)
};

export const changePassword = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/auth/change_password`, data)
};
