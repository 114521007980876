import React from "react";
import { UnorderedListOutlined } from '@ant-design/icons'

export const UserIcon = (props) => (
  <div
    style={{
      height: 25,
      width: 25,
      borderRadius: '50%',
      padding: '0px 4px 0px 4px',
      margin: '0px 4px 0px 4px',
      border: '1px solid #555555',
      cursor: 'pointer',
      background: '#fff'
    }}
    {...props}>
    <img
      alt={'Icon'}
      style={{ marginTop: -3 }}
      height={15} width={15}
      src={require('./user.svg')?.default}
    />
  </div>);

export const ProfileIcon = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
    title={props.title}>
    <img
      alt={'Icon'}
      height={20}
      width={20}
      src={require('./profile.svg')?.default}
    />
    <span
      style={{
        marginLeft: 10,
      }}
    >{props.label}</span>
  </div>);

export const FirsrIcon = (props) => (
  <div
    style={{
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid #e2e2e2',
      paddingTop: 12,
      paddingBottom: 12,
    }}
    {...props}
  >
    <img alt={'Icon'} height={20} width={20} src={require(`./profile.svg`)?.default} />
    <span style={{ marginLeft: 10 }}>{props.label}</span>
  </div>);

export const TaskIcon = (props) => (
  <div
    style={{
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: 12,
    }}
    {...props}
  >
    {/* <img alt={'Icon'} height={20} width={20} src={require(`./setting.svg`)?.default} /> */}
    <UnorderedListOutlined />
    <span style={{ marginLeft: 10 }}>{props.label}</span>
  </div>);

export const MiddleIcon = (props) => (
  <div
    style={{
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid #e2e2e2',
      paddingTop: 12,
      paddingBottom: 12,
    }}
    {...props}
  >
    <img alt={'Icon'} height={20} width={20} src={require(`./${props.icon}`)?.default} />
    <span style={{ marginLeft: 10 }}>{props.label}</span>
  </div>);

export const LastIcon = (props) => (
  <div
    style={{
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 12,
    }}
    {...props}
  >
    <img alt={'Icon'} height={20} width={20} src={require(`./${props.icon}`)?.default} />
    <span style={{ marginLeft: 10 }}>{props.label}</span>
  </div>);