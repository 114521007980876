
import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { getDocumentSummary } from '../Document/Services/requests.services';
import './style.scss';

const ModalNotiTask = ({ histories, actionRef }) => {
  const [isShowModal, setShowModal] = useState(false)
  const [listData, setListData] = useState([
    {
      title: "Tài liệu đến hạn",
      number: 5,
    },
    {
      title: "Tài liệu hết hạn",
      number: 0,
    },
    {
      title: "Từ chối phê duyệt",
      number: 2,
    },
    {
      title: "Xem xét tài liệu",
      number: 5,
    },
    {
      title: "Phê duyệt tài liệu",
      number: 3,
    },
  ])

  const [value, setValue] = useState([])

  // useEffect(() => {
  //   if (listData.length === 0) return setShowModal(false);
  //   else return setShowModal(true)
  // }, [listData])

  useEffect(() => {
    const userAction = {
      open: setShowModal
    }

    actionRef.current = userAction
  }, [])

  useEffect(async () => {
    setShowModal(localStorage.getItem("isLogin") == "1" || false)
    if (showModal) {
      const { data } = await getDocumentSummary()
      console.log('res', data)

      setListData([
        {
          title: "Tài liệu đến hạn",
          number: data?.dueDateCount,
        },
        {
          title: "Tài liệu hết hạn",
          number: data?.expiredCount,
        },
        {
          title: "Từ chối phê duyệt",
          number: data?.rejectCount,
        },
        {
          title: "Xem xét tài liệu",
          number: data?.reviewedCount,
        },
        {
          title: "Phê duyệt tài liệu",
          number: data?.approvedCount,
        },
      ])

    }
    return () => localStorage.setItem("isLogin", 0)
  }, [])


  const showModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    localStorage.setItem("isLogin", 0)
    setShowModal(false)
  }

  const handleOk = () => {
    setShowModal(false)
  }

  console.log('isShowModal', isShowModal)
  return (

    <Modal className='modal-task-noti'
      title="Nhắc nhở công việc"
      visible={isShowModal}
      onCancel={closeModal}
      footer={null}
    >
      <p className='title_header'>Bạn còn các đầu việc cần xử lý như sau:</p>

      {listData.map((item, index) => (<div key={index} className='item_task'>
        <div className='title_task'> - {item.title}</div>
        <div className='number_task'>{item.number}</div>

      </div>))}
    </Modal>
  )
}

export default ModalNotiTask;


