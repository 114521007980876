import axios from "axios";
import { API_METHOD, API_STATUS_CODE } from "../constants/api.constant";
import { TOKEN_NAME } from "../constants";
import { refreshToken } from "../utils/auth.utils";

export const configHeader = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    "Accept": "*/*",
    Authorization: token ? `Bearer ${token}` : null,
  };
  return headers;
};

class AxiosService {
  get(url, params, customHeader) {
    return this.doRequest(API_METHOD.GET, url, params, customHeader);
  }

  post(url, data, customHeader) {
    return this.doRequest(API_METHOD.POST, url, data, customHeader);
  }

  put(url, data, customHeader) {
    return this.doRequest(API_METHOD.PUT, url, data, customHeader);
  }

  delete(url, customHeader, data = '') {
    return this.doRequest(API_METHOD.DELETE, url, data, customHeader);
  }

  async doRequest(method, url, data, customHeader) {
    let cusConfig = configHeader();
    if (customHeader) {
      cusConfig = {
        ...cusConfig,
        ...customHeader,
      };
    }
    // if (type === 'FORM') {
    //   cusConfig = formConfig();
    // }
    // if (type === 'FIRE_BASE') {
    //     cusConfig = fireBaseConfig();
    // }
    const options = {
      method,
      url,
      headers: cusConfig,
    };
    if (method === API_METHOD.GET) {
      options.params = data;
    } else {
      options.data = data;
    }
    return axios(options)
      .then((res) => {
        return {
          status: res.status,
          data: res.data,
        };
      })
      .catch((err) => {
        const resp = err.response;
        if (!resp) {
          return {
            status: 503,
            data: {
              msg: "SERVICE UNAVAILABLE",
            },
          };
        }
        switch (resp.status) {
          case API_STATUS_CODE.NOT_FOUND:
          case API_STATUS_CODE.METHOD_NOT_ALLOW:
          case API_STATUS_CODE.SERVICE_UNAVAIBLE:
          // case API_STATUS_CODE.BAD_REQUEST: 
          case API_STATUS_CODE.BAD_REQUEST:
            {
              return resp;
            }
          case API_STATUS_CODE.UNAUTHORIZED:
          case API_STATUS_CODE.ERR_ACCESS_DENIED: {
            // message.error('ffff')
            refreshToken.call();
            // localStorage.setItem(TOKEN_NAME, '');
            // document.location.href = '/login';
            return resp;
          }
          default:
            return resp;
        }
      });
  }
}

export default new AxiosService();

// export const instance = axios.create({
//     baseURL: apiUrl,
//     timeout: apiTimeOut,
// });
// const customHeader = (headerSetting = {}) => ({
//     // 'Content-Type': 'application/json',
//     // Accept: 'application/json',
//     Authorization: 'Bearer ' + localStorage.getItem('___pharmaToken') || undefined,
//     ...headerSetting,
// });
//
// const networkErrorTemplate = (e, titleParam, content) => {
//     if (axios.isCancel(e)) {
//         console.debug('Request canceled!');
//         return;
//     }
//     console.debug('Request canceled!');
// };
//
// export const requestApi = async ({ apiOption, successCallback = () => {}, failCallback = () => {} }) => {
//     const method = apiOption?.method || 'get';
//     let headerSetting = {};
//     if (method === 'patch') headerSetting = { 'content-type': 'application/merge-patch+json' };
//     let successResult = null;
//     let errorResult = null;
//     await instance({
//         headers: customHeader(headerSetting),
//         ...apiOption,
//     })
//       .then(res => {
//           successCallback(res);
//           successResult = res;
//           return res;
//       })
//       .catch(res => {
//           if (axios.isCancel(res)) {
//               const error = { errorTitle: 'Request error', errorDescription: 'Request canceled '};
//               failCallback(error);
//               errorResult = error;
//               return error;
//           }
//
//           if (res && res.status === 400) {
//               const error = { errorTitle: 'Request error', errorDescription: 'Request canceled '};
//               failCallback(error);
//               errorResult = error;
//               return error;
//           }
//
//           return networkErrorTemplate(res);
//       });
//
//     return [successResult, errorResult];
// };
