import axios from 'axios';
import { CONFIG } from '../configs';
import { TOKEN_NAME } from '../constants';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, headers: {
    Authorization: 'Bearer ' + localStorage.getItem(TOKEN_NAME)
  }
});

export const deleteProject = async (projectId) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/delete/project/${projectId}`;
    const result = await axiosInstance.delete(url,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const deleteUser = async (userId) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/delete/user/${userId}`;
    const result = await axiosInstance.delete(url,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const resetPassword = async (id) => {
  try {
    return await axiosInstance.put(`api/update/reset-password/${id}`)
  } catch (e) {
    throw e;
  }
}

export const resetPasswordAdmin = async (id) => {
  try {
    return await axiosInstance.put(`${CONFIG.API_URL.AUTH}/auth/account/${id}/reset-password`)
  } catch (e) {
    throw e;
  }
}