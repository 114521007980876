export const DATE_FORMAT = {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD-MM-YYYY',
    D: 'DD/MM/YYYY',
    LL: 'YYYY-MM-DD',
    LLL: 'YYYY-MM-DD HH:mm:ss',
};

export const MODAL_TYPES = {
    ADD_FOLDER: 'ADD_FOLDER',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    EDIT_FOLDER: 'EDIT_FOLDER',
    EDIT_DOCUMENT: 'EDIT_DOCUMENT',
    REPLACE_DOCUMENT: 'REPLACE_DOCUMENT',
    DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
    PRINT_REQUEST: 'PRINT_REQUEST',
    VIEW_REQUEST: 'VIEW_REQUEST',
    TRAINING_REQUEST: 'TRAINING_REQUEST',
    REVIEW_REQUEST: 'REVIEW_REQUEST',
    APPROVE_LIST: 'APPROVE_LIST',
    REVIEW_DOC: "REVIEW_DOC",
    APPROVE_DOC: "APPROVE_DOC",
    APPROVE_REQ: "APPROVE_REQ",
}

export const APP_MODES = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DELETE_FILE: 'DELETE_FILE',
    DELETE_FOLDER: 'DELETE_FOLDER',
    REPLACE_FILE: 'REPLACE_FILE',
    PUBLIC_FILE: 'PUBLIC_FILE',
    DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
    PRINT_REQUEST: 'PRINT_REQUEST',
    VIEW_REQUEST: 'VIEW_REQUEST',
    TRAINING_REQUEST: 'TRAINING_REQUEST',
    REVIEW_REQUEST: 'REVIEW_REQUEST',
    REVIEW_DOC: "REVIEW_DOC",
    APPROVE_DOC: "APPROVE_DOC",
    APPROVE_REQ: "APPROVE_REQ",
}

export const DOCUMENT_STATUS = {
    0: 'DRAFT',
    1: 'ACTIVE',
    2: 'OBSOLETE'
}

export const ACTION_TYPES = {
    0: 'CREATE_FOLDER',
    1: 'UPDATE_FOLDER',
    2: 'DELETE_FOLDER',
    3: 'CREATE_FILE',
    4: 'UPDATE_FILE',
    5: 'DELETE_FILE',
    6: 'CHANGE_POSITION',
    7: 'REPLACE_FILE',
    8: 'REVIEW_FILE',
    9: 'APPROVE_FILE',
    10: 'REQUEST_PRINT',
    11: 'APPROVE_PRINT',
    12: 'REQUEST_TRAINING',
    13: 'APPROVE_TRAINING',
    14: 'REQUEST_DOWNLOAD',
    15: 'APPROVE_DOWNLOAD',
}

export const REQUEST_TYPES = {
    0: "PRINT",
    3: "DOWNLOAD",
    4: "VIEW",
}