import { TOKEN_NAME } from '../../../constants';

/**
 * Define a DateUtil that supports some functions to work with date time
 */
export default class TokenUtil {
    static buildCustomHeader() {
        const token = localStorage.getItem(TOKEN_NAME);
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOlsiRG9jdW1lbnRfQ3JlYXRlRmlsZSIsIkRvY3VtZW50X0NyZWF0ZUZvbGRlciIsIkRvY3VtZW50X0RlbGV0ZUZpbGUiLCJEb2N1bWVudF9EZWxldGVGb2xkZXIiLCJEb2N1bWVudF9FZGl0RmlsZSIsIkRvY3VtZW50X0VkaXRGb2xkZXIiLCJEb2N1bWVudF9MaXN0RGF0YSIsIkRvY3VtZW50X0xpc3REb2N1bWVudERhdGEiLCJEb2N1bWVudF9MaXN0UmVxdWVzdERvd25sb2FkIiwiRG9jdW1lbnRfTGlzdFJlcXVlc3RQcmludCIsIkRvY3VtZW50X0xpc3RSZXF1ZXN0VHJhaW5pbmciLCJEb2N1bWVudF9QdWJsaWNEb2N1bWVudCIsIkRvY3VtZW50X1JlbmFtZUZpbGUiLCJEb2N1bWVudF9SZXBsYWNlRmlsZSIsIkRvY3VtZW50X1JlcXVlc3REb3dsb2FkIiwiRG9jdW1lbnRfUmVxdWVzdFByaW50IiwiRG9jdW1lbnRfUmVxdWVzdFJldmlldyIsIkRvY3VtZW50X1JlcXVlc3RUcmFpbmluZyIsIlVzZXJNYW5hZ2FtZW50X0FkZFVzZXIiLCJVc2VyTWFuYWdhbWVudF9Bc3NpZ25SaWdodCIsIlVzZXJNYW5hZ2FtZW50X0RlbGV0ZVVzZXIiLCJVc2VyTWFuYWdhbWVudF9FZGl0VXNlciIsIlVzZXJNYW5hZ2FtZW50X1ZpZXdVc2VyIiwiVXNlck1hbmFnYW1lbnRfVmlld1VzZXIiLCJOVF9Db3JlX0FEQWNjb3VudEFwcCIsIk5UX0NvcmVfQURBY2NvdW50R3JvdXAiLCJOVF9Db3JlX0FjY291bnRCT0dyb3VwIiwiTlRfQ29yZV9BY2NvdW50Qk9Vc2VyIiwiTlRfQ29yZV9BY3RpdmVBY2NvdW50IiwiTlRfQ29yZV9BZGRVc2VyIiwiTlRfQ29yZV9DaGVja0FjY291bnRBcHAiLCJOVF9Db3JlX0NyZWF0ZUdyb3VwIiwiTlRfQ29yZV9EZWxldGVHcm91cCIsIk5UX0NvcmVfRGVsZXRlVXNlciIsIk5UX0NvcmVfR2V0SW5mb1VzZXIiLCJOVF9Db3JlX0xpc3RBY2NvdW50ZXJtaXQiLCJOVF9Db3JlX0xpc3RBcHAiLCJOVF9Db3JlX0xpc3RHcm91cFBlcm1pdCIsIk5UX0NvcmVfTGlzdFBlcm1pdCIsIk5UX0NvcmVfTGlzdFVzZXIiLCJOVF9Db3JlX1VwZGF0ZUdyb3VwIiwiTlRfQ29yZV9VcGRhdGVHcm91cFBlcm1pdCIsIk5UX0NvcmVfVXBkYXRlVXNlciJdLCJ1c2VyIjoie1wiSWRcIjoxMixcIklkQWNjb3VudFwiOjEyLFwiVXNlcm5hbWVcIjpcIm1hc3Rlcl9hZG1pblwiLFwiRnVsbE5hbWVcIjpcIk5ndXllbiBBMVwiLFwiYXBwQ29kZVwiOltcIk5UX0RvY3VtZW50XCIsXCJVc2VyTWFuYWdhbWVudFwiLFwiTlRfQ29yZVwiXSxcIlJvbGVzXCI6bnVsbH0iLCJqdGkiOiI2M2M2Zjk3NC05MDVhLTRlNDYtOTJlMC1hYmNjMmM0NzlkZTgiLCJleHAiOjE2NTUzOTQ2NjgsImlzcyI6IlBNX2lzc3VlIiwiYXVkIjoiUE1faXNzdWUifQ.-u5ff7itzHrahZMwm6ppkcjfZyUeXnjZx2kWUBbj8NY';
        return {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
        }
    }
}
