const moment = require('moment');

/**
 * Define a DateUtil that supports some functions to work with date time
 */
export default class DateUtil {
    static formatDate(date, format) {
        if (!date) {
            return '';
        }

        return moment(date).format(format);
    }
}
