export const requiredTextRule = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
];

export const numberRule = [
  {
    pattern: /^[0-9]*$/,
    message: 'Vui lòng chỉ điền số!',
  }
];

export const textAndNumberVi = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
  // {
  //   pattern: /^[a-zA-Z0-9 _.-]*$/,
  //   message: 'Vui lòng chỉ điền chữ và số',
  // }
];

export const textAndNumberVi2 = [
  // {
  //   pattern: /^[a-zA-Z0-9 _.-]*$/,
  //   message: 'Vui lòng chỉ điền chữ và số',
  // }
];

export const usernameRule = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
  {
    pattern: /^[a-z0-9]*$/,
    message: 'Vui lòng chỉ điền chữ thường và số',
  }
];

export const passRule = [
  {
    pattern: /^[a-zA-Z0-9 ]*$/,
    message: 'Vui lòng chỉ điền chữ và số',
  }
];
export const requiredNumberRule = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
  {
    pattern: /^[0-9]*$/,
    message: 'Vui lòng chỉ điền số!',
  }
]

export const requiredNumberWithCommaRule = [
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!',
  },
  {
    pattern: /^\d*\.?\,?\d*$/,
    message: 'Vui lòng chỉ điền số!',
  }
]

export const requiredEmailRule = [
  {
    message: 'Địa chỉ email không đúng!',
    type: "email",
  },
  {
    required: true,
    message: 'Vui lòng điền vào chỗ trống!'
  },
];