import {
    FileTwoTone,
    FolderTwoTone,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import {DATE_FORMAT, DOCUMENT_STATUS} from '../Constants';
import DateUtil from './DateUtil';

/**
 * Define a DataUtil that supports some functions to work with data processing
 */
export default class DataUtil {
    static treeDataConvert(jsonData) {
        if (!jsonData || jsonData.length === 0) {
            return [];
        }

        return jsonData.map((item) => {
            let isFile = !item._listFile && !item._listSubFolder;
            let title = item.Title;
            let rootLinks = this.getTreeTitles(title, []);
            let rootKey = this.getTreeKeys(item.Id, item.IdParent);
            let children = [];
            if (item._listFile && item._listFile.length > 0) {
                const subListFile = item._listFile.map(subFile => ({
                    id: subFile.Id,
                    title: subFile.Title,
                    type: subFile.Type,
                    version: subFile.Version,
                    titleEnglish: subFile.TitleEnglish,
                    createDate: subFile.CreateDate,
                    editDate: subFile.EditDate,
                    status: subFile.Status,
                    keyFile: subFile.KeyFile,
                    expireDate: subFile.ExpireDate,
                    validDate: subFile.ValidDate,
                    createBy: subFile.CreateBy,
                    remindBeforeDay: subFile.RemindBeforeDay,
                    parent: {
                        id: item.Id,
                        title: item.Title,
                    },
                    accessGroups: this.getAccessGroupValues(subFile._listAccessGroup),
                    icon: <FileTwoTone />,
                    links: this.getTreeTitles(subFile.Title, rootLinks),
                    key: this.getTreeKeys(0 - subFile.Id, rootKey),
                }));

                children.push(...subListFile);
            }

            if (item._listSubFolder && item._listSubFolder.length > 0) {
                const subListFolder = this.getChildrenWithSubFolder(item._listSubFolder, rootKey, rootLinks, item.Title);
                children.push(...subListFolder);
            }

            return Object.assign({}, {
                id: item.Id,
                title,
                createBy: item.CreateBy,
                key: rootKey,
                createDate: item.CreateDate,
                editDate: item.EditDate,
                createByGroup: item.CreateByGroup,
                accessGroups: this.getAccessGroupValues(item._listAccessGroup),
                links: rootLinks,
                children,
                icon: isFile ? <FileTwoTone /> : <FolderTwoTone />,
                parent: { id: item.Id, title },
            })
        })
    }

    static getChildrenWithSubFolder(subFolder, key, links, parentTitle) {
        return (subFolder || []).map(item => {
            let title = item.Title;
            let children = [];
            let rootKey = this.getTreeKeys(item.Id, key);
            let rootLinks = this.getTreeTitles(title, links)
            if (item._listFile && item._listFile.length > 0) {
                const subListFile = item._listFile.map(subFile => ({
                    id: subFile.Id,
                    title: subFile.Title,
                    type: subFile.Type,
                    version: subFile.Version,
                    createBy: subFile.CreateBy,
                    titleEnglish: subFile.TitleEnglish,
                    createDate: subFile.CreateDate,
                    editDate: subFile.EditDate,
                    status: subFile.Status,
                    keyFile: subFile.KeyFile,
                    expireDate: subFile.ExpireDate,
                    validDate: subFile.ValidDate,
                    remindBeforeDay: subFile.RemindBeforeDay,
                    parent: {
                        id: item.Id,
                        title: item.Title,
                    },
                    accessGroups: this.getAccessGroupValues(subFile._listAccessGroup),
                    icon: <FileTwoTone />,
                    links: this.getTreeTitles(subFile.Title, rootLinks),
                    key: this.getTreeKeys(subFile.Id, rootKey),
                }));

                children.push(...subListFile);
            }

            if (item._listSubFolder && item._listSubFolder.length > 0) {
                const subListFolder = this.getChildrenWithSubFolder(item._listSubFolder, rootKey, rootLinks, item.Title);
                children.push(...subListFolder);
            }

            const parent = !item.IdParent ? null : { id: item.IdParent, title: parentTitle };

            return {
                icon: <FolderTwoTone />,
                id: item.Id,
                title,
                key: rootKey,
                createBy: item.CreateBy,
                createDate: item.CreateDate,
                editDate: item.EditDate,
                createByGroup: item.CreateByGroup,
                accessGroups: this.getAccessGroupValues(item._listAccessGroup),
                links: rootLinks,
                children,
                parent,
            }
        })
    }

    static getTreeKeys(key, parentKey) {
        if (!parentKey) {
            return `${uuidv4()}-${key}`;
        }

        return `${parentKey}-${key}`;
    }

    static getTreeTitles(title, parentTitles) {
        if (!parentTitles || parentTitles.length === 0) {
            return [`${title}`];
        }

        return [...parentTitles, `${title}`];
    }

    static getAccessGroupValues(listAccessGroup) {
        return (listAccessGroup || []).reduce((result, item) => {
            result.push(item.IdGroup);
            return result;
        }, [])
    }

    static convertAccountStatusToOptions() {
        return Object.keys(DOCUMENT_STATUS).map((key) => {
            return {
                label: DOCUMENT_STATUS[key],
                value: key,
            }
        })
    }

    static toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    static fileToBlob = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    static formatAccountTable(documents) {
        return (documents || []).map((d, index) => ({
            ...d,
            validDate: d.validDate ? DateUtil.formatDate(d.validDate, DATE_FORMAT.L) : null,
            expireDate: d.expireDate ? DateUtil.formatDate(d.expireDate, DATE_FORMAT.L) : null,
            createDate: d.createDate ? DateUtil.formatDate(d.createDate, DATE_FORMAT.L) : null,
            key: `${uuidv4()}${index}-${d.keyFile}`,
        }))
    }

    static formatRequestTable(requestData) {
        return (requestData || []).map((item, index) => ({
            ...item,
            approveDate: item.approveDate ? DateUtil.formatDate(item.approveDate, DATE_FORMAT.L) : null,
            key: `${index}-${item.id}${uuidv4()}`,
        }))
    }
}