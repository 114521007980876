import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import '../../index.scss'
import { Button, Col, Divider, Form, message, Modal, Row, Space } from 'antd';
import { AuthContext } from "../../context/authContext/AuthContext";
import { loginAgain, loginSuccess } from "../../context/authContext/AuthActions";
import * as apis from '../../services/login'
import { ProFormText } from '@ant-design/pro-form';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { recoverPassword } from "../../services/get";
import { handleErrors } from "../../utils/handleErrors";
import { changePasswordApi, changePasswordApiReset, loginApi } from '../../apis/login'
// import * as loginAction from "../../redux/login/slice";
import { REFRESH_TOKEN, TOKEN_NAME } from "../../constants";

const Login = () => {
  const { dispatch, error, isFetching } = useContext(AuthContext);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState({})
  // const onFinish = (user) => apis.login({...user, isAdmin: true }, dispatch);
  const onFinish = async (data) => {
    const res = await loginApi(data)
    if (res?.data?.changePassword) {
      setVisible(true)
      setData({
        // _id: res?.data?.userId,
        token: res?.data?.token,
      })
      return
    }
    if (res.data.token) {
      const data = res.data
      dispatch(loginSuccess(res.data));
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token)
      localStorage.setItem(TOKEN_NAME, data.token)
      localStorage.setItem("roles", data.roles)
      localStorage.setItem("userId", data.userId)
      localStorage.setItem("username", data.username)
      localStorage.setItem("signUrl", data.signUrl || "")
      localStorage.setItem("isLogin", 1)
      localStorage.setItem("admin", data.admin ? 1 : 0)
      window.location.reload()
    } else {
      console.log('res', res)
      // setLoginErrors("Đăng nhập không thành công")
      message.error("Thông tin đăng nhập không chính xác" || res?.data?.message || "Đăng nhập không thành công")
    }
  }
  const handleRecoverPassword = async (email) => {
    try {
      const result = await recoverPassword(email);
      if (result?.status === 204) {
        setShowRecoverPassword(false);
        Modal.success({
          title: 'Khôi phục mật khẩu thành công!',
          content: (<div>
            <p>{`Vui lòng kiểm tra địa chỉ email ${email}!`}</p>
          </div>),
          onOk() { },
          okText: 'Đóng',
        });
      }
    } catch (e) {
      handleErrors(e);
    }
  }
  return (<div className={'container-login'}>
    <Modal
      visible={showRecoverPassword}
      title={'Khôi phục mật khẩu'}
      onCancel={() => setShowRecoverPassword(false)}
      width={440}
      maskClosable={false}
      destroyOnClose
      onOk={() => handleRecoverPassword('idqc.hcm@gmail.com')}
      okText={'Khôi phục'}
      cancelText={'Hủy'}
    >
      {'Mật khẩu sẽ được gửi vào địa chỉ email: idqc.hcm@gmail.com'}
    </Modal>
    <div className={'login'}>
      <Divider orientation={'center'}>{'Đăng nhập tài khoản'}</Divider>
      <Form layout={'vertical'} onFinish={(values) => !error && onFinish(values)}>
        <Row>
          {error && <p style={{ color: 'red' }}>{'Tài khoản hoặc mật khẩu không đúng!'}</p>}
        </Row>
        <Row>
          <Col span={24}>
            <ProFormText
              name="username"
              label={'Tài khoản'}
              fieldProps={{
                prefix: <UserOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Tài khoản'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tài khoản!',
                },
              ]}
              onChange={() => dispatch(loginAgain())}
            />
            <ProFormText.Password
              name="password"
              label={'Mật khẩu'}
              fieldProps={{
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Mật khẩu'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
              ]}
              onChange={() => dispatch(loginAgain())}
            />
            <Form.Item>
              <Button className={'button'} loading={isFetching} type="primary" htmlType="submit">{'Đăng nhập'}</Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <a onClick={() => setShowRecoverPassword(true)}>{'Quên mật khẩu?'}</a>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
    <Modal
      title="Thay đổi mật khẩu"
      centered
      closable={false}
      visible={visible}
      width={340}
      footer={null}
      destroyOnClose
    >
      <Form
        layout={"vertical"}
        onFinish={async (values) => {
          try {
            // setLoading(true);
            const result = await changePasswordApiReset({...values, token: data.token});
            if (result?.status === 200) {
              setVisible(false);
              localStorage.setItem(TOKEN_NAME, "")
              message.success("Thay đổi mật khẩu thành công! Vui lòng đăng nhập lại")
              // logoutUser();
            }
            else
              message.error(result?.data?.message)
            setLoading(false);
          } catch (e) {
            handleErrors(e, null, setLoading, 'Thay đổi mật khẩu KHÔNG thành công!');
            setHasError(true);
          }
        }}
      >
        {({ password }) => (<>
          {hasError && <p style={{ color: 'red' }}>{'Đã có lỗi xây ra, không thể thay đổi mật khẩu!'}</p>}
          <div style={{ color: "red", fontStyle: "italic", marginBottom: "10px" }}>Tài khoản của bạn đã được đặt lại mật khẩu, vui lòng đổi mật khẩu!</div>
          <ProFormText.Password
            name="password"
            label={'Mật khẩu mới'}
            fieldProps={{
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Mật khẩu mới'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
            ]}
            onChange={() => setHasError(false)}
          />
          <ProFormText.Password
            name="confirmPassword"
            label={'Xác nhận mật khẩu'}
            fieldProps={{
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Xác nhận mật khẩu'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value !== password) {
                    callback("Mật khẩu không đúng!");
                  } else {
                    callback();
                  }
                }
              }
            ]}
            onChange={() => setHasError(false)}
          />
          <Space>
            <Button type="primary" loading={loading} danger onClick={() => setVisible(false)}>{'Hủy'}</Button>
            <Button type="primary" loading={loading} htmlType="submit">{'Gửi'}</Button>
          </Space></>)}
      </Form>
    </Modal>
  </div>);
};

export default Login;