import axios from 'axios';
import { TOKEN_NAME } from '../constants';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, headers: {
    Authorization: 'Bearer ' + localStorage.getItem(TOKEN_NAME)
  }
});

export const getProfileByUserId = async (userId) => {
  const url = `/api/get/profile/${userId}`;
  const { data = {} } = await axiosInstance.get(url);
  return data;
};

export const getProfiles = async () => {
  const url = `/api/get/profiles`;
  const { data = [] } = await axiosInstance.get(url);
  return data;
};

export const getProjects = async () => {
  const url = `/api/get/projects`;
  const { data = [] } = await axiosInstance.get(url);
  return data;
};

export const getAuditTrails = async (payload, accessToken) => {
  const token = localStorage.getItem(TOKEN_NAME)
  const url = `/api/get/audit-trails`;
  const result = await axiosInstance.post(url, payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return result;
};

export const recoverPassword = async (email) => {
  try {
    const url = `/api/get/recover-password/${email}`;
    const result = await axiosInstance.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
