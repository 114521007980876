export const CONFIG = {
    /**
     * development
     * * */
    API_URL: {
        // BASE: process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin,
        BASE: process.env.BASE ? process.env.BASE : 'https://api-v2.tdsh.vienkiemnghiem.org.vn',
        CORE: process.env.REACT_APP_API ? process.env.REACT_APP_API + '/api' : window.location.origin + '/api',
        AUTH: process.env.REACT_APP_API_AUTH ? process.env.REACT_APP_API_AUTH : 'http://112.78.1.167:8088',
        DOC: process.env.REACT_APP_API_DOCUMENT ? process.env.REACT_APP_API_DOCUMENT : 'https://dms.document.ezlab.info/api/documents',
        PHARMA: process.env.REACT_APP_API_PHARMA ? process.env.REACT_APP_API_PHARMA : 'https://api-v2.tdsh.vienkiemnghiem.org.vn',
    },
};

// api: http://112.78.1.167:8080/api/accounts/get_app_by_username?username=admin_sample
//   account-module:
// - admin_sample/admin_sample - module NT_Sample
// - admin_test/admin_test - module NT_Test
// - admin_client/admin_client - module NT_Client
// - admin_task/admin_task - module NT_Task
