import {lazy} from 'react';

const Routes = [
  {
    name: 'Administrator Management',
    path: '/documents',
    Component: lazy(() => import('./pages/document')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/documents_list',
    Component: lazy(() => import('./pages/documentList')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/accounts',
    Component: lazy(() => import('./pages/account')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/accounts_list',
    Component: lazy(() => import('./pages/accountList')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/accounts_groups',
    Component: lazy(() => import('./pages/accountGroup')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/accounts_departments',
    Component: lazy(() => import('./pages/accountDepartment')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/clinic_list',
    Component: lazy(() => import('./pages/clinicList')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/standard_accept',
    Component: lazy(() => import('./pages/standardAccept')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/standard_cancel',
    Component: lazy(() => import('./pages/standardCancel')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/questions',
    Component: lazy(() => import('./pages/questions')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/news',
    Component: lazy(() => import('./pages/news')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/advertisements',
    Component: lazy(() => import('./pages/advertisements')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/images',
    Component: lazy(() => import('./pages/images')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/volunteer',
    Component: lazy(() => import('./pages/volunteer')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/projects',
    Component: lazy(() => import('./pages/projectManagement')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/activites',
    Component: lazy(() => import('./pages/activities')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  },
  {
    name: 'Administrator Management',
    path: '/',
    Component: lazy(() => import('./pages/volunteer')),
    exact: true,
    breadcrumb: {
      name: 'routes.breadcrumbs.admin',
      href: '#'
    }
  }
];

export default Routes;
