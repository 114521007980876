import React, {Suspense, useContext, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch as RouteSwitch} from 'react-router-dom';
import Login from './pages/login';
import './index.scss';
import {AuthContext} from "./context/authContext/AuthContext";
import Routes from './routes';
import Container from './pages/container';
import {Spin} from "antd";

export function App() {
  const { user, token } = useContext(AuthContext);
  const [hideMenu, setHideMenu] = useState(false);
  return (
    <Router basename="/">
      <RouteSwitch>
        {token && Routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => (
              <Suspense fallback={<Spin size='large' />}>
                <Container user={user} setHideMenu={setHideMenu} hideMenu={hideMenu}>
                  <Component user={user} hideMenu={hideMenu} />
                </Container>
              </Suspense>
            )}
          />
        ))}
        <Route path="**">{token ? <Redirect to={'/'} /> : <Login />}</Route>
      </RouteSwitch>
    </Router>
  );
}

export default App;
