import React from 'react';
import { Button } from 'antd';

export default function Footer({onSubmit, submitting, submitBtnLabel, cancelBtnLabel, onCancel}) {
    return (
        <div className="footer-modal">
            <div className="buttons">
                <Button danger onClick={onCancel}>
                    {cancelBtnLabel}
                </Button>
                <Button type="primary" onClick={onSubmit} loading={submitting}>
                    {submitBtnLabel}
                </Button>
            </div>
        </div>
    )
}