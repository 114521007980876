import React from 'react';
import { Form } from 'antd';

const defaultLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

const ResponsiveForm = (props) => {
  return (
    <Form {...defaultLayout} {...props}>
      {props.children}
    </Form>
  )
}

export default ResponsiveForm;
