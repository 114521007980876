import axios from 'axios';
import {loginFailure, loginStart, loginSuccess} from "../context/authContext/AuthActions";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const login = async (user, dispatch) => {
    try {
        dispatch(loginStart());
        const res = await axiosInstance.post(`/api/auth/login`, user);
        dispatch(loginSuccess(res.data));
    } catch (e) {
        dispatch(loginFailure());
    }
};