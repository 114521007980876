import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Modal, Upload, Button, Select, InputNumber, Col, Row, message, Tooltip } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { APP_MODES, DATE_FORMAT } from '../../Constants';

import ResponsiveForm from '../ResponsiveForm';
import Footer from './Footer';
import { addNewAccount, updateAccountStaff, uploadFile } from '../../Services/account.services';
import NotifyUtil from '../../Utils/NotifyUtil';
import { textAndNumberVi, usernameRule } from '../../../../utils/rules';
import './styles.scss';
import DataUtil from '../../Utils/DataUtil';
import { ResetPasswordConfirm } from '../../../../forms/reset-password-confirm';

const { Option } = Select;

const AccountModal = ({ isShowModal, onClose, mode, listDepartment, listGroupUser, actionRef, dataDetai, afterSubmit }) => {
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleReset, setVisibleReset] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    let payload = {
      fullName: values.fullName,
      email: values.email,
      departmentId: values.departmentId,
      position: values.position,
      username: values.accountInfo,
      password: values.accountPass,
      staffId: values.staffId,
      permissionId: values.permissionId,
      signUrl: values.signUrl,
    }
    // if (!isEdit) payload = {
    //   ...payload,
    //   staffId: values.staffId,
    //   username: values.accountInfo,
    //   password: values.accountPass,
    // }
    console.log('first', fileList?.[0]?.size > 5 * 1024 * 1024, (fileList?.[0] && fileList?.[0]?.type !== "image/png" && fileList?.[0]?.type !== "image/jpeg" && fileList?.[0]?.type !== "image/jpg"))
    if (fileList?.[0]?.size > 5 * 1024 * 1024 || (fileList?.[0] && fileList?.[0]?.uid !== "-1" && fileList?.[0]?.type !== "image/png" && fileList?.[0]?.type !== "image/jpeg" && fileList?.[0]?.type !== "image/jpg")) {
      setLoading(false)
      return
    }
    // const base64File = await DataUtil.toBase64(fileList[0].originFileObj);
    if (fileList?.[0] && fileList?.[0]?.uid !== '-1') {
      const res = await uploadFile("/upload/user-sign", {
        file: fileList?.[0]?.originFileObj
      })
      payload.signUrl = res?.data?.filePath
    }

    if (fileList?.[0] && fileList?.[0]?.uid === '-1') {
      payload.signUrl = fileList?.[0]?.url
    }

    const resData = isEdit ? await updateAccountStaff(dataDetai.id, payload) : await addNewAccount(payload);
    if (dataDetai.id == localStorage.userId) {
      localStorage.signUrl = payload.signUrl || ""
    }
    setLoading(false);
    if (resData.status !== 200) {
      NotifyUtil.showApiErrorWMessage(resData?.data?.message || "Dữ liệu không hợp lệ!");
    } else {
      NotifyUtil.showApiSuccess(`${isEdit ? "Sửa" : "Thêm"} tài khoản thành công`);
      handleCancelAndCloseModal();
    }

  }

  const handleOpen = () => {
    setVisible(true)
  };

  const handleCancelAndCloseModal = () => {
    setIsEdit(false);
    onClose && onClose();
    form.resetFields();
    setFileList([])
    setVisible(false)
  }

  const setFieldsValue = (values) => {
    console.log('values', values)
    form.setFieldsValue({
      ...values,
      accountInfo: values.account?.username,
      accountPass: values?.account?.password,
      departmentId: values?.department?.id,
      permissionId: values?.permission?.id,
    })
    const signUrl = values?.signUrl
    if (signUrl) {
      const arraySplit = signUrl.split("/")
      const fileName = arraySplit[arraySplit.length - 1]
      setFileList([
        {
          uid: '-1',
          name: fileName,
          status: 'done',
          url: signUrl,
        },
      ])
    }
    setIsEdit(true);
  }

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      close: handleCancelAndCloseModal,
      open: handleOpen,
      setFieldsValue: setFieldsValue,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  const updateDefaultProps = {
    name: 'file',
    multiple: false,
    onChange({ _, fileList }) {
      setFileList(fileList);
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList: fileList,
    uploading: false,
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';

      // if (!isPNG) {
      //   message.error(`${file.name} Định dạng file ảnh không hợp lệ, chỉ bao gồm png, jpg, jpeg`);
      // }
      return false;
    },
  }

  console.log('fileList', fileList)

  return (
    <Modal
      title={isEdit ? "Chỉnh sửa tài khoản" : t("ACCOUNT.MODAL.USER.addHeader")}
      visible={visible}
      onCancel={handleCancelAndCloseModal}
      width={720}
      maskClosable={false}
      footer={[
        <Footer
          key="document-footer"
          cancelBtnLabel={t("ACCOUNT.MODAL.USER.Buttons.cancel")}
          submitBtnLabel={mode === APP_MODES.CREATE ? t("ACCOUNT.MODAL.USER.Buttons.save") : t("ACCOUNT.MODAL.USER.Buttons.update")}
          submitting={loading}
          onSubmit={form.submit}
          onCancel={handleCancelAndCloseModal}
        />
      ]}
    >

      <ResponsiveForm
        form={form}
        name="create-update-document"
        onFinish={onSubmit}
        layout="horizontal"
      // initialValues={{
      //   ...values,
      // }}
      >
        <Form.Item>
          <span className="ant-form-text">Thông tin tài khoản</span>
        </Form.Item>
        <div className='container_item'>
          <Row gutter={24}>
            <Col span={24} >
              <Form.Item
                label={t("ACCOUNT.MODAL.USER.fullName")}
                name="fullName"
                labelAlign="left"
                rules={textAndNumberVi}
              >
                <Input maxLength={128} placeholder='Nhập họ và tên nhân viên' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} >
              <Form.Item
                label={t("ACCOUNT.MODAL.USER.staffId")}
                name="staffId"
                labelAlign="left"
                rules={textAndNumberVi}
              >
                <Input disabled={isEdit} maxLength={25} placeholder='Nhập mã nhân viên' />
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item
                label={t("ACCOUNT.MODAL.USER.email")}
                name="email"
                labelAlign="left"
                rules={[{ required: true, message: `${t("ACCOUNT.MODAL.USER.Form_Error.is_required")}` },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                ]}
              >
                <Input
                  type="email"
                  maxLength={128}
                  placeholder='Nhập email nhân viên' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} >
              <Form.Item
                compact
                label={t("ACCOUNT.MODAL.USER.department")}
                name="departmentId"
                labelAlign="left"
              // rules={[{ required: true, message: `${t("ACCOUNT.MODAL.USER.Form_Error.is_required")}` }]}
              >
                <Select
                  suffixIcon={false}
                  placeholder="Chọn phòng ban"

                >
                  {
                    listDepartment && listDepartment.map((item, index) => (
                      <Option value={item.departmentId} key={item.departmentId}>{item.departmentName}</Option>

                    ))
                  }
                </Select>

              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item
                label={t("ACCOUNT.MODAL.USER.position")}
                name="position"
                labelAlign="left"
                rules={textAndNumberVi}
              >
                <Input
                  maxLength={128}
                  placeholder="Nhập chức vụ nhân viên"

                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.Item>

          <span className="ant-form-text">Thông tin đăng nhập</span>
        </Form.Item>
        <div className='container_item'>
          <Form.Item
            label={t("ACCOUNT.MODAL.USER.username")}
            name="accountInfo"
            labelAlign="left" n
            rules={usernameRule}
          >
            <Input
              disabled={isEdit}
              maxLength={128}
              placeholder='Nhập tên đăng nhập chỉ gồm ký tự thường và chữ số'
            />
          </Form.Item>
          <Form.Item
            label={t("ACCOUNT.MODAL.USER.password")}
            name="accountPass"
            labelAlign="left"
            rules={[{ required: true, message: `${t("ACCOUNT.MODAL.USER.Form_Error.is_required")}` },
            {
              min: 8,
              message: `${t("ACCOUNT.MODAL.USER.Form_Error.min_lenght")}`
            },
            {
              pattern: /^[a-zA-Z0-9 $&+,:;=?@#|'<>.^*()%!-]*$/,
              message: 'Vui lòng chỉ điền chữ, số và kí tự đặc biệt, không được nhập tiếng việt có dấu',
            },
            ]}
          >
            <Input.Password disabled={isEdit} maxLength={128} min={8} placeholder='Mật khẩu tối thiểu 8 ký tự' />
          </Form.Item>
        </div>
        <Form.Item style={{ margin: 0 }}>
          <span className="ant-form-text">Phân quyền</span>
        </Form.Item>
        <div className='container_item'>
          <Form.Item
            label={t("ACCOUNT.MODAL.USER.groupUser")}
            name="permissionId"
            labelAlign="left"
            rules={[{ required: true, message: `${t("ACCOUNT.MODAL.USER.Form_Error.is_required")}` }]}
          >
            <Select
              suffixIcon={false}
              placeholder="Chọn nhóm người dùng"
            >
              {
                listGroupUser && listGroupUser.map((item) => (
                  <Option value={item.groupId} key={item.groupId}>{item.groupName}</Option>

                ))
              }
            </Select>
          </Form.Item>
        </div>
        {isEdit &&
          <Form.Item style={{ margin: 0 }}>
            <span className="ant-form-text">Chữ ký</span> <Tooltip title="Độ phân giải hỗ trợ tốt nhất: 250x200">
              <QuestionCircleOutlined />
            </Tooltip>
          </Form.Item>
        }
        {isEdit &&
          <div className='container_item'>
            <Form.Item>
              <Upload {...updateDefaultProps}>
                <Button type="dashed" disabled={fileList.length > 0} className="upload-document-btn" icon={<UploadOutlined />}>{"Tải chữ ký"}</Button>
              </Upload>
            </Form.Item>
            {fileList?.[0]?.size > 5 * 1024 * 1024 && <div style={{ color: "red", fontStyle: "italic" }}>
              Dung lượng ảnh không được quá 5MB
            </div>}
            {(fileList?.[0] && fileList?.[0]?.uid !== "-1" && fileList?.[0]?.type !== "image/png" && fileList?.[0]?.type !== "image/jpeg" && fileList?.[0]?.type !== "image/jpg") && <div style={{ color: "red", fontStyle: "italic" }}>
              Định dạng file ảnh không hợp lệ, chỉ bao gồm png, jpg, jpeg
            </div>}
          </div>
        }
        {
           localStorage.getItem("admin") == "1" && isEdit && <Button type='primary' onClick={() => setVisibleReset(true)} >Đặt lại mật khẩu</Button>
        }
      </ResponsiveForm>
      <ResetPasswordConfirm
        data={dataDetai}
        isShow={visibleReset}
        setIsShow={setVisibleReset}
        isAdmin={true}
      />
    </Modal>
  )
}

export default AccountModal;
