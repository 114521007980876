import TokenUtil from '../Utils/TokenUtil';
import axiosService from '../../../services/axiosService';
import { CONFIG } from '../../../configs';
import { DATE_FORMAT, REQUEST_TYPES } from '../Constants';
import DateUtil from '../Utils/DateUtil';

export function getWaitingApproveRequest() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.DOC}/get_list_waiting_approve`, null, CUSTOM_HEADER).then(({ data }) => {
    return (data.data || []).map((item, index) => ({
      key: `${index}-${item.idRequest}`,
      title: item.documentName,
      requestedBy: item.createRequestName,
      priority: item.priority,
      requestId: item.idRequest,
      fileID: item.idFile,
      type: item.type,
      approveID: item.id,
      request: REQUEST_TYPES[item.type],
    }));
  }).catch(({ data: error }) => {
    return error;
  })
}

export function getWaitingReviewDocument(action = "REVIEW") {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.AUTH}/document/document/list?action=${action}`, null, CUSTOM_HEADER).then(({ data }) => {
    console.log('data', data)
    return (data.data || []).map((item, index) => ({
      ...item,
      key: `${index}-${item.createdDate}`,
      fileID: item.documentUrl,
      title: item.fileName,
      requestId: item.documentId,
      requestedBy: item.createdBy,
      priority: "Medium",
    }));
  }).catch(({ data: error }) => {
    return error;
  })
}

export function getWaitingApproveDocument() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.DOC}/get_list_approve`, null, CUSTOM_HEADER).then(({ data }) => {
    return (data.data || []).map((item, index) => ({
      key: `${index}-${item.idRequest}`,
      requestId: item.id,
      fileID: item.idFile,
      requestedBy: item.nameUser,
      title: item.documentName,
      priority: "Medium",
    }));
  }).catch(({ data: error }) => {
    return error;
  })
}

export function reviewDocument(data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.DOC}/review_file`, data, CUSTOM_HEADER);
}

export function approveDocument(data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.DOC}/approve_file`, data, CUSTOM_HEADER);
}

export function approvePrintRequest(data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.DOC}/approve_print`, data, CUSTOM_HEADER);
}

export function completedRequest(requestID, requestType) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.DOC}/complete_request_file?idRequest=${requestID}&type=${requestType}`, null, CUSTOM_HEADER);
}

export function approveDownloadRequest(data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.DOC}/approve_download`, data, CUSTOM_HEADER);
}

export function createReviewRequest(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.AUTH}/document/document-view/${id}`, data, CUSTOM_HEADER);
}

export function createDownloadRequest(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.AUTH}/document/document-download/${id}`, data, CUSTOM_HEADER);
}

export function createPrintRequest(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.AUTH}/document/document-print/${id}`, data, CUSTOM_HEADER);
}

export function createRecallRequest(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.post(`${CONFIG.API_URL.AUTH}/document/document-recall/${id}`, data, CUSTOM_HEADER);
}

export function putRecallRequest(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.put(`${CONFIG.API_URL.AUTH}/document/document-recall/${id}`, data, CUSTOM_HEADER);
}

const handleData = ({ data }) => {
  const mapArray = data.approval.map(x => x.id)
  data.created.forEach(c => {
    if (!mapArray.includes(c.id)) {
      data.approval.push({
        ...c,
        isCreated: 1
      })
    }
  })
  data.approval.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
  return data.approval.filter(x => !(x.approvedStatus === "REJECT" && !x.isCreated) && !(x.approvedStatus === "APPROVED" && !x.isCreated) && !(x.approvedStatus === "RECALLED" && !x.isCreated))
  // return data.approval.filter(x => x.approvedStatus !== "REJECT" && !(x.approvedStatus === "APPROVED" && !x.isCreated))
}

export function getListRequestDownload() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.AUTH}/document/document-download/list`, null, CUSTOM_HEADER).then(handleData);
}

export function putRequestDownload(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.put(`${CONFIG.API_URL.AUTH}/document/document-download/${id}`, data, CUSTOM_HEADER);
}

export function getListRequestPrint() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.AUTH}/document/document-print/list`, null, CUSTOM_HEADER).then(handleData);
}

export function putRequestPrint(id, data) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.put(`${CONFIG.API_URL.AUTH}/document/document-print/${id}`, data, CUSTOM_HEADER);
}

export function getListRequestView() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.AUTH}/document/document-view/list`, null, CUSTOM_HEADER).then(handleData);
}

export function getListRequestRecall() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.AUTH}/document/document-recall/list`, null, CUSTOM_HEADER).then(handleData);
}

export function deleteRequestView(id) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.delete(`${CONFIG.API_URL.AUTH}/document/document-view/${id}`, null, CUSTOM_HEADER)
}

export function getDocumentSummary() {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.AUTH}/document/document/summary`, null, CUSTOM_HEADER);
}
//check view


export function getRequestDetail(requestID, requestType) {
  const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
  return axiosService.get(`${CONFIG.API_URL.DOC}/get_detail_request?idRequest=${requestID}&type=${requestType}`, null, CUSTOM_HEADER).then(({ data }) => {
    if (!data.data) {
      return {};
    }

    return {
      id: data.data.id,
      fileID: data.data.idFile,
      pages: data.data.pages,
      purpose: data.data.purpose,
      requestedBy: data.data.requestName,
      requestedDate: data.data.requestDate ? DateUtil.formatDate(data.data.requestDate, DATE_FORMAT.L) : null,
      numberOfCopies: data.data.numberOfCopies,
    }
  });
}