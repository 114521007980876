import axios from 'axios';
import { TOKEN_NAME } from '../constants';
import axiosService from './axiosService';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: {
  Authorization: 'Bearer ' + localStorage.getItem(TOKEN_NAME) 
} });

export const updateUser = async (userId, payload) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/update/user/${userId}`;
    const result = await axiosInstance.put(url, payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateProject = async (projectId, payload) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/update/project/${projectId}`;
    const { data = {} } = await axiosInstance.put(url, payload, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const updateUserPasswordByUserId = async (userId, payload, token) => {
  const url = `/api/update/password/${userId}`;
  const result = await axiosInstance.put(url, {
      password: payload.password,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_NAME)}`
      }
    }
  );
  return result;
};

export const updateProjectMemberStatus = async (userId, payload) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/update/project-member/status/${userId}`;
    const result = await axiosInstance.put(url, payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const lockProject = async (projectId, payload) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/update/project/lock/${projectId}`;
    const result = await axiosInstance.put(url,
      {
        status: payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateStatus = async (userId, values) => {
  try {
    const token = localStorage.getItem(TOKEN_NAME)
    const url = `/api/update/status/${userId}`;
    const result = await axiosInstance.put(url, { ...values },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log(e);
    return null;
  }
};